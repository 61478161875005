export default {
    jsonld() {
        const schema = {
            "@context": "http://schema.org",
            "@graph":[
                {
                    "@type":"Corporation",
                    "name":this.$store.state.generaleSettings.find(x => x.slug === 'site_name')&&this.$store.state.generaleSettings.find(x => x.slug === 'site_name').value?this.$store.state.generaleSettings.find(x => x.slug === 'site_name').value:'ilajak',
                    "url":`https://www.ilajak.com${this.$router.history.current.fullPath}`,
                    "logo": "https://www.ilajak.com/images/nav-logo.png",
                    "contactPoint":[
                        {
                            "@type": "ContactPoint",
                            "telephone": this.$store.state.generaleSettings.find(x => x.slug === 'phone1')&&this.$store.state.generaleSettings.find(x => x.slug === 'phone1').value?this.$store.state.generaleSettings.find(x => x.slug === 'phone1').value:'',
                            "contactType": "Customer service",
                            "email":this.$store.state.generaleSettings.find(x => x.slug === 'email')&&this.$store.state.generaleSettings.find(x => x.slug === 'email').value?this.$store.state.generaleSettings.find(x => x.slug === 'email').value:''
                        }
                    ],
                    "address": {
                        "@type": "PostalAddress",
                        "streetAddress": this.$store.state.generaleSettings.find(x => x.slug === 'address')&&this.$store.state.generaleSettings.find(x => x.slug === 'address').value?this.$store.state.generaleSettings.find(x => x.slug === 'address').value:'ZİYA GÖKALP MH. SÜLEYMAN DEMİREL BULV. MALL OF İSTANBUL OFİS BLOK NO:7E/92  BAŞAKŞEHİR / İSTANBUL',
                        "addressLocality": "Istanbul","postalCode": "34490",
                        "addressCountry": "Turkey"
                    },
                    "sameAs":[
                        this.$store.state.socialMedia.find(x => x.slug === 'facebook')&&this.$store.state.socialMedia.find(x => x.slug === 'facebook').value?this.$store.state.socialMedia.find(x => x.slug === 'facebook').value:'',
                        this.$store.state.socialMedia.find(x => x.slug === 'youtube')&&this.$store.state.socialMedia.find(x => x.slug === 'youtube').value?this.$store.state.socialMedia.find(x => x.slug === 'youtube').value:'',
                        this.$store.state.socialMedia.find(x => x.slug === 'instagram')&&this.$store.state.socialMedia.find(x => x.slug === 'instagram').value?this.$store.state.socialMedia.find(x => x.slug === 'instagram').value:'',
                        this.$store.state.socialMedia.find(x => x.slug === 'twitter')&&this.$store.state.socialMedia.find(x => x.slug === 'twitter').value?this.$store.state.socialMedia.find(x => x.slug === 'twitter').value:'',
                    ]
                },
                {
                    '@type': 'Article',
                    mainEntityOfPage: {
                        '@type': 'WebPage',
                        '@id': `https://www.ilajak.com${this.$router.history.current.fullPath}`,
                    },
                    headline: this.page.title?this.page.title:'',
                    description: this.page.meta_tags?this.page.meta_tags.seo_description:'',
                    image : {
                        "@type"  : "ImageObject",
                        "url"    : this.page.image?this.page.image:'',
                        "height" : 360,
                        "width"  : 640
                    },
                    author: {
                        '@type': 'Person',
                        name: this.$store.state.generaleSettings.find(x => x.slug === 'site_name')&&this.$store.state.generaleSettings.find(x => x.slug === 'site_name').value?this.$store.state.generaleSettings.find(x => x.slug === 'site_name').value:'ilajak',
                        url:'https://www.ilajak.com'
                    },
                    datePublished: this.page.created_at,
                    dateModified: this.page.updated_at,
                    "articleBody": this.page.content?this.page.content.replace(/(<([^>]+)>)/gi, ""):'',
                    publisher: {
                        '@type': 'Organization',
                        name: this.$store.state.generaleSettings.find(x => x.slug === 'site_name')&&this.$store.state.generaleSettings.find(x => x.slug === 'site_name').value?this.$store.state.generaleSettings.find(x => x.slug === 'site_name').value:'ilajak',
                        logo: {
                            '@type': 'ImageObject',
                            url: 'https://www.ilajak.com/images/nav-logo.png',
                        },
                    },
                }
            ]
        }

        if (this.page.breadcrumbs&&this.page.breadcrumbs.length>0) {
            var breadcrumbList= []
            for(let i=0;i<this.page.breadcrumbs.length;i++){
                var item = {
                    "@type": "ListItem",
                    "position": i+1,
                    "name": `${this.page.breadcrumbs[i].name}`,
                    "item": `${this.page.breadcrumbs[i].item}`,
                }
                breadcrumbList.push(item)
            }
            schema['@graph'].push({
                "@type": "BreadcrumbList",
                "itemListElement": breadcrumbList
            })
        }

        if (this.page.faq&&this.page.faq.length>0) {
            var questions= []
            for(let i=0;i<this.page.faq.length;i++){
                var oneQuestion = {
                    "@type": "Question",
                    "name": `${this.page.faq[i].title}`,
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": `${this.page.faq[i].content}`
                    }
                }
                questions.push(oneQuestion)
            }
            schema['@graph'].push({
                "@type": "FAQPage",
                "mainEntity": questions
            })
        }
      if (this.$route.path.includes('tags')) {
        let tags = []

        for (let i = 0; i < this.page.items.data.length; i++) {
          let oneTag = {
            "@type": "ListItem",
            "name": `${this.page.items.data[i].title}`,
            "url": `https://www.ilajak.com${this.$i18n.locale !== 'ar' ? `/${this.$i18n.locale}` : '/'}${this.page.items.data[i].slug}`,
          }
          tags.push(oneTag)
        }
        schema['@graph'].push({
          "@type": "CollectionPage",
          "name": this.$store.state.generaleSettings.find(x => x.slug === 'site_name') && this.$store.state.generaleSettings.find(x => x.slug === 'site_name').value ? this.$store.state.generaleSettings.find(x => x.slug === 'site_name').value : 'ilajak',
          "url": `https://www.ilajak.com${this.$router.history.current.fullPath}`,
          "description": this.page.meta_tags ? this.page.meta_tags.seo_description : '',
          "image": this.page.image ? this.page.image : '',
          "mainEntity": {
            "@type": "ItemList",
            "itemListElement": tags
          }
        })
      }
        return schema
    },
};
