export default {
    head() {
        const head = {
            titleTemplate:'',
            meta:[]
        }

        if(this.page) {

            if (this.page.meta_tags) {
              head.titleTemplate = ` ${this.page.title?this.page.meta_tags.seo_title:''} | %s`
                head.meta.push(
                    {
                        hid: 'description',
                        name: 'description',
                        content: `${this.page.meta_tags.seo_description}`
                    },
                    {
                        hid: 'keywords',
                        name: 'keywords',
                        content: `${this.page.meta_tags.seo_keywords}`
                    },
                    {
                        hid: 'twitter:title',
                        name: 'twitter:title',
                        content: `${this.page.meta_tags.seo_title}`
                    },
                    {
                        hid: 'twitter:description',
                        name: 'twitter:description',
                        content: `${this.page.meta_tags.seo_description}`
                    },
                    {
                        hid: 'twitter:image',
                        name: 'twitter:image',
                        content: `${this.page.image}`
                    },
                    {
                        hid: 'og:title',
                        name: 'og:title',
                        content: this.page.title,
                    },
                    {
                        hid: 'og:url',
                        name: 'og:url',
                        content : `${this.$router.history.current.fullPath}`,
                    },
                    {
                        hid: 'og:image',
                        property: 'og:image',
                        content : `${this.page.image}`,
                    },

                    {
                        hid: 'og:image:alt',
                        name: 'og:image:alt',
                        content : `${this.page.title}`,
                    },
                    {
                        hid: 'og:description',
                        name: 'og:description',
                        content : `${this.page.summary}`,
                    },
                );

                if(this.page.meta_tags.alternative) {
                    const lang = Object.keys(this.page.meta_tags.alternative)
                    const alternate = Object.values(this.page.meta_tags.alternative)
                    for (let i=0;i < alternate.length;i++ ) {
                        // var x = {
                        //     name: 'alternate',
                        //     type: 'application/rss+xml',
                        //     title: this.page.meta_tags.seo_title,
                        //     href: alternate[i]
                        // }
                        var x = {
                          name:'alternate',
                            hreflang: lang[i]==='en'?'x-default':lang[i],
                            href: alternate[i],
                            defer: true
                        }
                        head.meta.push(x)
                    }
                }
            }
            else {
              head.titleTemplate = ` ${this.page.title?this.page.title:''} | %s`
            }
        }
        return head;
    },
};
